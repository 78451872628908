'use strict';

intellidealerAppCoreModule.config(['$stateProvider', function ($stateProvider) {
  $stateProvider.state(
    'navigation', {
      url: '/{library}',
      abstract: true,
      templateUrl: 'app/scripts/modules/core/views/navigation.html',
      controller: 'NavigationController',
      resolve: {
        help: function () {
          return '';
        },
        userConfiguration: ['$rootScope', 'userConfigurationService', 'cdkAnalyticsService', function ($rootScope, userConfigurationService, cdkAnalyticsService) {
          return userConfigurationService.getFlags().then(function (data) {
            if (data.contactId) {
              cdkAnalyticsService.setGlobalField('company', data.contactId.company);
              cdkAnalyticsService.setGlobalField('division', data.contactId.division);
              cdkAnalyticsService.setGlobalField('customerId', data.contactId.customerNumber);
              $rootScope.gadimension2 = data.contactId.customerNumber;
              $rootScope.gadimension3 = data.contactId.company;
              $rootScope.gadimension4 = data.contactId.division;
            }
            return data;
          });
        }],
        dealershipConfiguration: ['dealershipConfigurationService', '$stateParams', 'themeApplicationService', function (dealershipConfigurationService, $stateParams, themeApplicationService) {
          return dealershipConfigurationService($stateParams.library.toUpperCase()).then(function (value) {
            themeApplicationService(value.baseColor.symbol);
            return value;
          });
        }],
        features: ['sessionService', function (sessionService) {
          return sessionService.populateFeatures();
        }],
        isIREnabled: ['sessionService', function (sessionService) {
          return sessionService.isIREnabled();
        }],
        customerAlerts: ['alertHistoryResource', function (alertHistoryResource) {
          return alertHistoryResource.myDealerCustomerAlert.query().$promise;
        }],
        menuStructure: ['userConfiguration', 'customerAlerts', 'features', 'myDealerDealershipLinks', 'myDealerTermsAndConditionsLinks', 'isIREnabled', function (userConfiguration, customerAlerts, features, myDealerDealershipLinks, myDealerTermsAndConditionsLinks, isIREnabled) {
          var menuStructure = [];
          menuStructure.push({
            title: 'mydealer.ui.dashboard.breadcrumb',
            state: 'navigation.dashboard'
          });
          if (userConfiguration.myDealerAccountAccess) {
            var myAccount = {
              title: 'mydealer.ui.navigation.my_account',
              subMenu: []
            };
            myAccount.subMenu.push({
              title: 'mydealer.ui.profile.breadcrumb',
              state: 'navigation.myAccount.profile'
            });
            myAccount.subMenu.push({
              title: 'mydealer.ui.invoices.breadcrumb',
              state: 'navigation.myAccount.invoices'
            });
            if (userConfiguration.displayStatements && userConfiguration.hasStatements) {
              myAccount.subMenu.push({
                title: 'mydealer.ui.statements.breadcrumb',
                state: 'navigation.myAccount.statements'
              });
            }
            menuStructure.push(myAccount);
          }
          if (userConfiguration.myDealerEquipmentAccess ||
            userConfiguration.accessRentalOptions ||
            userConfiguration.myDealerEquipmentSalesAccess) {
            var equipment = {
              title: 'mydealer.ui.navigation.equipment',
              subMenu: []
            };
            if (userConfiguration.myDealerEquipmentAccess) {
              equipment.subMenu.push({
                title: 'mydealer.ui.my_equipment.breadcrumb',
                state: 'navigation.equipment.myEquipment'
              });
            }
            if (!isIREnabled) {
              if (features['PFW.6512'] && userConfiguration.myDealerEquipmentAccess) {
                equipment.subMenu.push({
                  title: 'mydealer.ui.my_rentals.breadcrumb',
                  state: 'navigation.equipment.myRentals'
                });
              }
            }
            if ((features['PFW.6245'] || features['PFW.6186'] || features['PFW.6218'])
              && userConfiguration.myDealerEquipmentAccess
              && userConfiguration.showTelematics) {
              equipment.subMenu.push({
                title: 'mydealer.ui.telematics.breadcrumb',
                state: 'navigation.equipment.telematics'
              });
            }
            if (userConfiguration.myDealerEquipmentSalesAccess) {
              equipment.subMenu.push({
                title: 'mydealer.ui.equipment_for_sale.breadcrumb',
                state: 'navigation.equipment.equipmentForSale'
              });
            }
            if (!isIREnabled) {
              if (features['PFW.6512'] && userConfiguration.accessRentalOptions) {
                equipment.subMenu.push({
                  title: 'mydealer.ui.equipment_for_rent.breadcrumb',
                  state: 'navigation.equipment.equipmentForRent'
                });
              }
            }
            menuStructure.push(equipment);
          }
          if (userConfiguration.myDealerPartsAccess) {
            var partsMenu = {
              title: 'mydealer.ui.navigation.parts',
              subMenu: []
            };
            partsMenu.subMenu.push(
              {
                title: 'mydealer.ui.shop_for_parts.breadcrumb',
                state: 'navigation.parts.shopForParts'
              },
              {
                title: 'mydealer.ui.my_shopping_lists.breadcrumb',
                state: 'navigation.parts.myShoppingLists'
              },
              {
                title: 'mydealer.ui.my_orders.breadcrumb',
                state: 'navigation.parts.previousOrders'
              },
              {
                title: 'mydealer.ui.my_parts_purchases.breadcrumb',
                state: 'navigation.parts.orderHistory'
              }
            );

            if (features['PFW.6414']) {
              partsMenu.subMenu.push({
                title: 'mydealer.ui.cnhDsp.breadcrumb',
                state: 'navigation.parts.cnhDspLanding'
              });
            }

            if (features['PFW.6421']) {
              partsMenu.subMenu.push({
                title: 'mydealer.ui.ariPartstream.breadcrumb',
                state: 'navigation.parts.ariPartstream'
              });
            }

            menuStructure.push(partsMenu);
          }
          if (userConfiguration.myDealerQuoteAccess) {
            menuStructure.push({
              title: 'mydealer.ui.quotes.breadcrumb',
              state: 'navigation.quotes'
            });
          }
          if (userConfiguration.myDealerAlertManagementAccess && userConfiguration.hideAlerts !== true && customerAlerts.length > 0) {
            menuStructure.push({
              title: 'mydealer.ui.alerts.breadcrumb',
              state: 'navigation.alerts'
            });
          }

          function validateLocation(contactId, businessLocation, value) {
            if (contactId.company === value.company && contactId.division === value.division && (value.location === '' || value.location === businessLocation)) {
              return true;
            }
            return false;
          }

          if (myDealerDealershipLinks && myDealerDealershipLinks.length > 0) {
            var myLinks = {
              title: 'mydealer.ui.navigation.links',
              subMenu: []
            };
            angular.forEach(myDealerDealershipLinks, function (value) {
              if (value.subType !== 'EQUIPMENT_FOR_SALE' && validateLocation(userConfiguration.contactId, userConfiguration.businessLocation, value)) {
                myLinks.subMenu.push({
                  isLink: true,
                  title: value.description,
                  state: value.url
                });
              }
            });
            menuStructure.push(myLinks);
          }

          return menuStructure;

        }],
        dealerContactInformation: ['dealershipResource', 'globalAlertService', function (dealershipResource, globalAlertService) {
          return dealershipResource.dealerContactInformation.get(
            {},
            function () {
            },
            globalAlertService.handleErrorResponse
          ).$promise;
        }],
        myDealerDealershipLinks: [
          'dealershipLinksResource',
          'globalAlertService',
          function (dealershipLinksResource, globalAlertService) {
            return dealershipLinksResource.myDealerDealershipLinks.query(
              {},
              function () {
              },
              globalAlertService.handleErrorResponse
            ).$promise;
          }],
        myDealerTermsAndConditionsLinks: [
          'dealershipLinksResource',
          'globalAlertService',
          function (dealershipLinksResource, globalAlertService) {
            return dealershipLinksResource.myDealerTermsAndConditionsLinks.query(
              {},
              function () {
              },
              globalAlertService.handleErrorResponse
            ).$promise;
          }],
        language: ['languageService',
          function (languageService) {
            return languageService.populate();
          }],
        dealershipContactTypes: [
          'dealershipResource',
          'globalAlertService',
          function (dealershipResource, globalAlertService) {
            return dealershipResource.dealershipContactTypes.get(
              {},
              function () {
              },
              globalAlertService.handleErrorResponse
            ).$promise;
          }],
        customizedMessages: ['customizedMessagesService', function (customizedMessagesService) {
          return customizedMessagesService.populateAssignedMessages();
        }],
        locations: ['locationService', function (locationService) {
          var latLongValue = localStorage.getItem('latLongValue');
          latLongValue = latLongValue ? latLongValue : "";
          return locationService.populateAssignedLocations(false, latLongValue);
        }],
        googleAnalytics: ['$rootScope', 'cdkAnalyticsService', 'locationService', 'locations', function ($rootScope, cdkAnalyticsService, locationService, locations /*wait on this*/) {
          if (cdkAnalyticsService.isServiceAvailable !== false) {
            return cdkAnalyticsService.loadTrackingIds().then(function (response) {
              cdkAnalyticsService.setGlobalField('branch', locationService.getBusinessLocation());
              $rootScope.gadimension5 = locationService.getBusinessLocation();
              return response;
            });
          }
        }]
      }
    });
}]);
