'use strict';

intellidealerAppCoreModule.service('locationService', function (contactsResource,googleResource, globalAlertService, $state, $q,securityResource) {
  var service = this;
  var assignedLocations = [];
  var userLocation = '';
  var cityPostalCode = '';

  service.getAssignedLocations = function () {
    return angular.copy(assignedLocations);
  };
  service.getGeoLocation = function () {
    return angular.copy(userLocation);
  };
  service.getCityOrPostalCode = function () {
    return angular.copy(cityPostalCode);
  };
  service.clearCityPostalCode = function () {
    cityPostalCode = '';
    return angular.copy(cityPostalCode);
  }

  service.populateAssignedLocations = function (publicMode, latLongValue) {
    return new Promise((resolve, reject) => {
      return contactsResource.assignedLocations.query(
        {publicMode: publicMode, userLocation: latLongValue},
        function (response) {
          assignedLocations = response;
          var position = latLongValue.split(',');
          assignedLocations.forEach((elm, index) => {
            elm.distance = elm.distance != 0 ? elm.distance : "";
            //For guest mode, the first value in Location Selected field is "Select Location", to skip index ;
            // the element from second value,in below lines index is being changed.
            elm.index = (publicMode) ? index : index + 1;
            elm.index = (publicMode && elm.isDefaultLocation) ? "" : elm.index;
          })
          //check if google maps has been loaded (this populateAssignedLocations method is called from elsewhere now)
          try {
            google.maps;
            resolve( service.populateGeolocationAssignedLocations(publicMode, latLongValue, position, assignedLocations));
          }
          catch (e) {
            //load the google maps script and wait for it to load before continuing
            service.getGoogleMapApiKey().then(function (googleResponse) {
              $.getScript("https://maps.googleapis.com/maps/api/js?loading=async&key=" + googleResponse.apiKey);
              setTimeout(() => {
                resolve( service.populateGeolocationAssignedLocations(publicMode, latLongValue, position, assignedLocations));
              }, 1000);
            });
          }
        },
        globalAlertService.handleErrorResponse
      ).$promise;
    });
  };

  service.populateGeolocationAssignedLocations = function(publicMode, latLongValue, position, assignedLocations) {
    if (latLongValue && position.length > 0) {
      var latlng = new google.maps.LatLng(position[0], position[1]);
      service.getCountryByLatLng(latlng).then(function (addressResult) {
        assignedLocations.forEach((elm, index) => {
          if (!elm.isMiles && addressResult.formatted_address.includes("USA")) {
            elm.distance = _.round(elm.distance * 0.6214, 1);
            elm.isMiles = true;
          }
        })
        userLocation = latLongValue;
      })
    }
    return assignedLocations;
  };

  service.getGoogleMapApiKey = function () {
    return googleResource.googleApiKey.get().$promise;
  }

  service.getGeoLocationForAddress = function (publicMode, cityOrPostalCode) {
    const geocoder = new google.maps.Geocoder();
    return $q(function (resolve, reject) {
      geocoder.geocode({
          address: cityOrPostalCode,
          region : $state.$current.locals.globals.userConfiguration.country,
        },
        function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            userLocation = `${results[0].geometry.location.lat()},${results[0].geometry.location.lng()}`;
            service.populateAssignedLocations(publicMode, userLocation).then(function (response) {
              cityPostalCode = cityOrPostalCode;
              if (results[0].formatted_address.includes("USA")) {
                assignedLocations.forEach((elm, index) => {
                  elm.distance = _.round(elm.distance * 0.6214, 1);
                  elm.isMiles = true;
                })
              }
              globalAlertService.addSuccess('mydealer.ui.parts.userLocation.success');
              resolve(assignedLocations);
            });
          } else {
            globalAlertService.addError('mydealer.ui.parts.userLocation.zero_result_error');
            reject(status);
          }
        }
      );
    });
  }

  service.getCountryByLatLng = function (latlng) {
    const geocoder = new google.maps.Geocoder();
    return $q(function (resolve, reject) {
      geocoder.geocode({
          latLng: latlng
        },
        function (results, status) {
          if (status === google.maps.GeocoderStatus.OK) {
            resolve(results[0]);
          } else {
            globalAlertService.addError('mydealer.ui.parts.userLocation.zero_result_error');
            reject(status);
          }
        }
      );
    });
  }

  service.showError = function (error) {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        globalAlertService.addError('mydealer.ui.parts.userLocation.permission_denied_error');
        break;
      case error.POSITION_UNAVAILABLE:
        globalAlertService.addError('mydealer.ui.parts.userLocation.position_unavailable_error');
        break;
      case error.TIMEOUT:
        globalAlertService.addError('mydealer.ui.parts.userLocation.timeout_error');
        break;
    }
  }

  service.getBusinessLocation = function () {
    var foundLocation = assignedLocations.find(function (assignedLocation) {
      return assignedLocation.isDefaultLocation;
    });
    // If we don't find a location, select the first one from the array so the user can still login
    if (!foundLocation && assignedLocations[0]) {
      return assignedLocations[0].location;
    } else {
      return foundLocation && foundLocation.location;
    }
  };

  service.getDefaultLocation = function () {
    return localStorage.getItem('_defaultLocation') || this.getBusinessLocation();
  };

  service.setDefaultLocation = function (defaultLocation) {
    localStorage.setItem('_defaultLocation', defaultLocation);
  };

  service.clearDefaultLocation = function () {
    localStorage.removeItem('_defaultLocation');
  };

  service.isOtherwiseCountry = function () {
    var otherwiseCountries = ['AU'];
    var countryCode = $state.$current.locals.globals.userConfiguration.country;
    return otherwiseCountries.indexOf(countryCode) >= 0;
  };

});
