'use strict';

intellidealerAppCoreModule.service('cdkAnalyticsService',
  function (appConfigurationService, userConfigurationService, cdkAnalyticsResource, $q, $timeout, $state, breadcrumbsService, locationService, $rootScope, sessionService) {
    var self = this;
    /**
     * we keep an array of registered ids in order to prevent duplicates
     */
    var currentIds = [];

    this.isServiceAvailable = undefined;

    /**
     * Custom dimensions that gets passed analytical requests.
     * The google Analytic account must register these ids with same index.
     * We pass library and applicationType on all requests but other dimensions are limited to some requests.
     */
    var dimensionProperties = {
      'custom_map': {
        dimension1: 'library',
        dimension2: 'customerId',
        dimension3: 'company',
        dimension4: 'division',
        dimension5: 'branch'
      }
    };

    var gtag = window.gtag;

    this.setLibrary = function () {
      return sessionService.getVersionPromise().then(function (versionData) {
        $rootScope.gaSerialNumber = versionData.serialNumber,
        gtag('set', {library: appConfigurationService.getLibrary() + '-' + versionData.serialNumber});
      });
    };

    /**
     * Return an object containing google analytics properties of current state .
     * @param defaultProperties(Optional): merges current state properties with defaultProperties (if provided)
     */
    function getCurrentStateProperties(defaultProperties) {
      var breadcrumbs = breadcrumbsService.getBreadcrumbNames($state.$current).breadcrumbs;

      var result = {
        page_path: $state.$current.name,
        page_title: _.map(breadcrumbs, 'text').join(' / '),
        dimension1: appConfigurationService.getLibrary() + '-' + $rootScope.gaSerialNumber,
        dimension2: $rootScope.gadimension2,
        dimension3: $rootScope.gadimension3,
        dimension4: $rootScope.gadimension4,
        dimension5: $rootScope.gadimension5,
      };
      if (defaultProperties) {
        result = _.merge(result, defaultProperties);
      }
      return result;
    }

    this.logPageView = function () {
      // wait one digest cycle in order to get new state window location
      $timeout(function () {

        var stateProperties = getCurrentStateProperties({page_location: window.location.href});
        // checkout page exceptionally reports branch as user selected branch:
        if (stateProperties.page_path === 'navigation.shopping.shoppingCart.checkout') {
          stateProperties.branch = locationService.getDefaultLocation();
        }
        gtag('event', 'page_view', stateProperties);
      });
    };
    this.logPageExit = function () {
      gtag('event', 'page_exit', getCurrentStateProperties({
        event_category: 'general',
        event_label: 'Page Exit',
        transport: 'beacon'
      }));
    };

    this.logLogin = function (username) {
      gtag('event', 'login', getCurrentStateProperties({
        customerId: username,
        event_category: 'general',
        event_label: 'Client Login'
      }));
    };

    this.logLogout = function (username) {
      gtag('event', 'logout', getCurrentStateProperties({
        customerId: username,
        page_path: 'login',
        page_title: 'MyDealer',
        event_category: 'general',
        event_label: 'Client Logout'
      }));
    };

    /**
     * Reports adding an item to shopping cart
     */
    this.logAddToCart = function (partNumber, quantity, partObject) {
      gtag('event', 'add_to_cart', getCurrentStateProperties({
        'items': [
          {
            'id': partNumber,
            'name': partObject ? partObject.description : '',
            'category': 'PART',
            'brand': partObject ? partObject.vendorName : '',
            'quantity': quantity,
            'price': partObject ? partObject.customerPrice : ''
          }
        ]
      }));
    };

    /**
     * Reports removing an item from shopping cart
     */
    this.logRemoveFromCart = function (partNumber, partObject) {
      gtag('event', 'remove_from_cart', getCurrentStateProperties({
        'items': [
          {
            'id': partNumber,
            'name': partObject ? partObject.description : '',
            'category': 'PART',
            'brand': partObject ? partObject.vendorName : '',
            'quantity': partObject ? partObject.quantity : undefined,
            'price': partObject ? partObject.customerPrice : ''
          }
        ],
      }));
    };

    /**
     * Converts shopping cart items to google analytics items
     */
    function createGoogleAnalyticsItemsFromCart(cartItems) {
      var gaItems = [];
      cartItems.forEach(function (item) {
        gaItems.push({
          'id': item.partNumber,
          'name': item.description,
          'category': 'PART',
          'brand': item.vendorName,
          'quantity': item.quantity,
          'price': item.customerPrice
        });
      });
      return gaItems;
    }

    /**
     * Reports removing of all items from shopping cart
     */
    this.logRemoveAllFromCart = function (shoppingCartItems) {
      if (shoppingCartItems && shoppingCartItems.length) {
        gtag('event', 'remove_from_cart', getCurrentStateProperties({
          'items': createGoogleAnalyticsItemsFromCart(shoppingCartItems),
        }));
      }
    };

    /**
     * Reports submitting an order
     */
    this.logSubmitOrder = function (cart, referenceNumber, isGuest) {
      gtag('event', 'purchase', getCurrentStateProperties({
        'transaction_id': referenceNumber,
        'affiliation': isGuest ? 'guest' : 'signed-in',
        'value': cart.total,
        'currency': 'USD',
        'items': createGoogleAnalyticsItemsFromCart(cart.items)
      }));
    };

    /**
     * Reports a part detail view
     */
    this.logPartView = function (partObject) {
      gtag('event', 'view_item', getCurrentStateProperties({
        'items': createGoogleAnalyticsItemsFromCart([partObject]),
      }));
    };

    /**
     * Sets a global field to be included in all GA reports
     */
    this.setGlobalField = function (fieldName, fieldValue) {
      var setting = {};
      setting[fieldName] = fieldValue;
      gtag('set', setting);
    };

    /**
     * Reset google analytics in order to provide new tracking Ids
     */
    this.reset = function () {
      currentIds.length = 0;
      // remove all custom datalayers (set, event, config)
      for (var i = dataLayer.length; i--;) {
        if (dataLayer[i][0] === 'set' || dataLayer[i][0] === 'event' || dataLayer[i][0] === 'config') {
          dataLayer.splice(i, 1);
        }
      }
      self.setLibrary();
    };

    /**
     * Loads cdk/dealer tracking Ids
     */
    this.loadTrackingIds = function () {
      function registerNewId(trackingId) {
        if (trackingId && currentIds.every(function (currentId) {
          return currentId !== trackingId;
        })) {
          currentIds.push(trackingId);
          gtag('config', trackingId, Object.assign({}, dimensionProperties, {'send_page_view': false}));
        }
      }

      if (self.isServiceAvailable !== false) {
        return $q.all([cdkAnalyticsResource.cdkTrackingId.get().$promise,
          cdkAnalyticsResource.dealerTrackingId.get().$promise]).then(function (ids) {

          // check if google analytics is available
          if (window.google_tag_manager && window.ga && window.ga.create) {
            self.isServiceAvailable = true;
          } else {
            console.log("Google Analytics is not available");
            gtag = angular.noop;
            self.isServiceAvailable = false;
            cdkAnalyticsResource.logBlockedService().post();
            return;
          }

          self.reset();
          registerNewId(ids[0].id);
          registerNewId(ids[1].id);
          gtag('require', 'ec');
        });
      }
    };

    $rootScope.$on('$stateChangeSuccess', function (event, toState, toStateParams, fromState) {
      if (toState.name !== fromState.name) {
        self.logPageView();
      }
    });
  }
);
